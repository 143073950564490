<template>
    <div>
        <base-dialog :show="!!error" title="An error occurred" @close="handleError">
        <p>{{ error }}</p>
        </base-dialog>
        <base-dialog :show="isLoading" title="Authenticating..." fixed>
        <base-spinner></base-spinner>
        </base-dialog>
        <base-card>
        <form @submit.prevent="submitForm">
            <div class="form-control">
            <label for="email">E-Mail</label>
            <input type="email" id="email" v-model.trim="email" />
            </div>
            <div class="form-control">
            <label for="password">Password</label>
            <input type="password" id="password" v-model.trim="password" />
            </div>
            <p
            v-if="!formIsValid"
            >Please enter a valid email and password (must be at least 6 characters long).</p>
            <base-button>Login</base-button>
        </form>
        </base-card>
    </div>
</template>
<script>
import get from 'lodash/get';
import BaseButton from './ui/BaseButton.vue';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js'

export default {
  name: "Login",
  components: {
    BaseButton
  },
  data() {
    return {
      email: '',
      password: '',
      formIsValid: true,
      isLoading: false,
      error: null,
      state: '',
      redirectUrl: '',
      authApp: process.env.VUE_APP_ROOT_API
    };
  },
  computed: {
  },
  mounted() {
    console.log('COGNITO_CLIENTID', process.env.VUE_APP_COGNITO_CLIENTID),
    console.log('COGNITO_USERPOOLID', process.env.VUE_APP_COGNITO_USERPOOLID),
    console.log('COGNITO_ENVIRONMENT', process.env.VUE_APP_ENVIRONMENT)
  },
  methods: {
    async submitForm() {
      this.formIsValid = true;
      if (
        this.email === '' ||
        !this.email.includes('@') ||
        this.password.length < 6
      ) {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        email: this.email,
        password: this.password,
      };

      console.log('[Info] Login actionPayload', actionPayload)

      try {
        await this.login(actionPayload);
        console.log('[Info] Login succesfull. Redirecting to FW')
      } catch (err) {
        console.log('[Error] err', err)
        this.error = err.message || 'Failed to authenticate, try later.';
      }

      this.isLoading = false;
    },
    async login(payload) {

        const clientId = this.$route.query.client_id
        const poolData = {
            UserPoolId: process.env.VUE_APP_COGNITO_USERPOOLID,
            ClientId: clientId,
        }

        const userPool = new CognitoUserPool(poolData)

        const callbackUrl = this.$route.query.redirect_uri
        const state = get(this.$route.query, 'state', '')
        const nonce = get(this.$route.query, 'nonce', '')

        const authenticationData = {
            Username: payload.email,
            Password: payload.password,
            ValidationData: {
              state: state,
              nonce: nonce
            },
            ClientMetadata: {
              'CallbackURLs' : 'callbackUrl'
            }
        }

        const authenticationDetails = new AuthenticationDetails(authenticationData)

        const userData = {
            Username: payload.email,
            Pool: userPool,
        }

        const cognitoUser = new CognitoUser(userData)

        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: function (result) {
                    const returnUrl = `${callbackUrl}#id_token=${result.getIdToken().getJwtToken()}&access_token=${result.getAccessToken().getJwtToken()}&expires_in=${result.getAccessToken().getExpiration()}&token_type=Bearer&state=${state}&nonce=${nonce}`
                    window.location = returnUrl
                },
                onFailure: function (err) {
                    console.log('[Info] err', err)
                    err.result = err.message;
                    reject(err)
                }
            })
        })
    }
  },
};
</script>

<style scoped>
form {
  margin: 1rem;
  padding: 1rem;
}

.form-control {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
  margin-bottom: 0.5rem;
  display: block;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  border: 1px solid #ccc;
  padding: 0.15rem;
}

input:focus,
textarea:focus {
  border-color: #3d008d;
  background-color: #faf6ff;
  outline: none;
}
</style>
